import * as React from "react";
import styled from "styled-components";
import Footer from "../footer/Footer";




const BaseComponent = styled.div`
  min-height: 100vh;
  display: flex;
  width: 100%; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(15px + 3vmin);
  color: white;
  background-color: black;
  @media (max-width: 100%) {
    width: 80%;
  }
`;
export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
  color: black;
  flex-wrap: wrap;
  margin-top: 90px;
`;

export const Heading = styled.h3`
display: flex;
gap: 20px;
flex-direction: row;
word-spacing: 10px;
margin-top: 1px;
`;



const MapHeading = styled.div`
display: flex;
flex-direction: column;
word-spacing: 10px;
`
const Line = styled.div`
      border-left: 10px dotted white;
      padding-left: 20px;  
`
const Subline = styled.div`
      padding-left: 50px;  
`
const Paragraph = styled.p`
  color: #ff6600;
  font-size: calc(15px + 3vmin);
  text-align: center;
`


const HomePage = () => {
  return (
    
    <BaseComponent>
      <h2>Cahill's Couriers </h2>
      <Paragraph>Operating for over 40 years</Paragraph>
      <Heading>
      <h5>Cairns to Port Douglas Couriers and</h5>
      <h5>Mossman Couriers</h5>
      </Heading>
      <MapHeading>
      <Line>
      <p>Portsmith</p>
      <h3>CAIRNS Depart 11:00am</h3>
      <Subline>
      <p>Palmcove</p>
      <p>Oak Beach</p>
      </Subline>
      <h3>PORT DOUGLAS Arrive 1:30pm</h3>
      <Subline>
      <p>Craiglie</p>
      <p>Macrossan St</p>
      </Subline>
      <h3>MOSSMAN Arrive 3:00pm</h3>
      <Subline>
      <p>Sawmill Rd</p>
      <p>Mill St</p>
      <p>Mossman Depot</p>
      </Subline>
      <h3>CAIRNS Arrive 5pm</h3>
      </Line>
      </MapHeading>
      <Footer />
    </BaseComponent>
  );
};

export default HomePage;
