import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "../home/HomePage";
import NotFound from "../pages/notfound/Notfound";
import About from "../pages/about/About";
import Contact from "../pages/contact/contact";




const AppRouter = () => {



  return (
    <Router>
    
      <Routes>  
        <Route>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
