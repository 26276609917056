import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    body {  
      font-family: 'Roboto', sans-serif;
  background-color: rgb(26, 26, 26);
/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 636x) 
  and (-webkit-min-device-pixel-ratio: 3) { 

}

  button {
font-size: 20px;
color: white;
text-transform: uppercase;
text-decoration: none;
padding: 10px 10px;
cursor: pointer;
background-color: rgb(26, 26, 26);
line-height: 1em;
font-weight: bold;
border-radius: 20px;
display: inline-grid;
margin-left: 10px;

  } 
}

`;
