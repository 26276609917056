import * as React from "react";
import styled from "styled-components";
import moment from "moment";

const StyledFooter = styled.div`
  background-color: rgb(26, 26, 26);
  min-height: 10%;
  padding-bottom: 0vh;
  /* equal to footer height */
  margin-bottom: -12px;
  align-items: center;
  color: white;
  width: 100%;
  text-align: center;
  @media (max-width: 100%) {
    width: 80%;
  }
`;

const Row = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, auto));
  grid-gap: 150px;
  margin-right: 20%;
  margin-left: 20%;
  margin-top: 20%;
  margin-bottom: 10%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: 0 auto;
  margin-right: 0 auto;
`;

const Heading = styled.h5`
  font-size: 30px;
  margin-bottom: 40px;
  text-align: center;
  font-weight: bold;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 0 auto;
  margin: 0 auto;
  text-align: center;
`;

const TextBody = styled.p`
  color: white;
  font-size: 20px;
  font-weight: 300;
  align-items: center;
`;

const Copyright = styled.div`
  max-width: 0 auto;
  display: flex;
  font-size: 13px;
  margin-left: 20px;
`;



const Footer = () => {



    return (
      <StyledFooter>
    <Container>
      <Row>
        <Column>
          <Heading>Hours</Heading>
          <TextBody>Mon - Fri 7:30am to 12pm</TextBody>
          <TextBody>After Hours: Email preferred</TextBody>
        </Column>
        <Column>
          <Heading>Contact</Heading>
          <TextBody>info@cahillscouriers.com.au</TextBody>
          <TextBody>Phone: 40 35 13 66</TextBody>
        </Column>
        <Column>
          <Heading>Postal address</Heading>
          <TextBody>Shed 20/2 Toohey St</TextBody>
          <TextBody>Portsmith</TextBody>
        </Column>
      </Row>
    </Container>
      <Copyright>
        <p>Copyright &copy; 82 Learn Pty Ltd {moment().format("YYYY")}.</p>
      </Copyright>
      </StyledFooter>
    );
  };
  
  export default Footer;
